/* You can add global styles to this file, and also import other style files */
$enable-rounded: false;
$enable-smooth-scroll: false;
$link-color: #000;
$link-decoration: none;

@import "./../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 2rem;
$btn-font-weight: 500;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1.75rem;
$btn-padding-y: 1rem;
$btn-padding-x: 2.5rem;
$input-btn-line-height: 1;
$input-padding-x: 0rem;
$body-bg: #fdf8f2;
$font-family-sans-serif: "Suisse Intl", sans-serif;
$form-floating-label-transform: scale(0.8) translateY(-0.5rem) translateX(0rem);
$font-weight-bold: 500; // Configuration
$font-weight-bolder: 500; // Configuration
$grid-gutter-width: 1rem;
@import "./../node_modules/bootstrap/scss/functions";
@import "./../node_modules/bootstrap/scss/variables";
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "dark": $dark,
);
$enable-smooth-scroll: false;
$border-color: #d4d4d4;
$btn-font-size-lg: $font-size-base;
$container-padding-x: $grid-gutter-width * 2;
@import "./../node_modules/bootstrap/scss/variables-dark";
@import "./../node_modules/bootstrap/scss/maps";
@import "./../node_modules/bootstrap/scss/mixins";
@import "./../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "./../node_modules/bootstrap/scss/root";
@import "./../node_modules/bootstrap/scss/reboot";
@import "./../node_modules/bootstrap/scss/type";
@import "./../node_modules/bootstrap/scss/images";
@import "./../node_modules/bootstrap/scss/containers";
@import "./../node_modules/bootstrap/scss/grid";
@import "./../node_modules/bootstrap/scss/forms";
@import "./../node_modules/bootstrap/scss/buttons";
@import "./../node_modules/bootstrap/scss/button-group";
@import "./../node_modules/bootstrap/scss/nav";
@import "./../node_modules/bootstrap/scss/navbar";
@import "./../node_modules/bootstrap/scss/close";
@import "./../node_modules/bootstrap/scss/tables";
@import "./../node_modules/bootstrap/scss/toasts";

// Helpers
@import "./../node_modules/bootstrap/scss/helpers";

// Utilities
@import "./../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// font face imports
@font-face {
  font-family: "Suisse Intl";
  src: url("/assets/SuisseIntl-Medium.woff2") format("woff2"),
    url("/assets/SuisseIntl-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Intl";
  src: url("/assets/SuisseIntl-Regular.woff2") format("woff2"),
    url("/assets/SuisseIntl-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Suisse Intl", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$prefix}font-sans-serif);
$font-family-code: var(--#{$prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 14;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.15;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: inherit;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);

$display-font-family: $font-family-sans-serif;
$display-font-style: null;
$display-font-weight: 400;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.875em;

$sub-sup-font-size: 0.75em;

// fusv-disable
$text-muted: var(--#{$prefix}secondary-color); // Deprecated in 5.3.0
// fusv-enable
// Headings
h1 {
  font-size: 38px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.2px;
}
h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.2px;
}
h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
}
h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
}
h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.display-1 {
  font-family: "Suisse Intl", sans-serif;
  font-size: 80px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: -0.2px;
}
@media screen and (max-width: 768px) {
  .display-1 {
    font-size: 35px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: -1.2px;
  }
}
// body
body {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.01em;
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background: #000;
    width: 6px;
    overflow: hidden;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: transparent;
    // background-color: #f6f2ef;
  }
}
strong {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.01em;
}
button {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}
caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

// Styles

a {
  line-height: 0.7 !important;
}

.bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
img {
  object-fit: cover;
}
.action {
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
}
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
.eyebrow {
  font-family: "Suisse Intl", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  letter-spacing: -0.14px;
}
.actionable,
.actionable svg {
  cursor: pointer;
}
.inactionable {
  cursor: default;
}

.vertical-divider {
  height: 48%;
  width: 1px;
  min-height: 20px;
}

.text-white * {
  color: #fff;
}

@media screen and (min-width: 991px) {
  .text-lg-white {
    color: #fff !important;
  }
  .text-lg-black {
    color: #000 !important;
  }
}
